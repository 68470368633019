import request from '@/utils/request'

const couponApi = {
    setCoupon: '/admin/AdminSetting/couponAdd', // 生成优惠券
    couponList: '/admin/AdminSetting/couponList', // 优惠券列表
}
/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 * }
 * @param parameter
 * @returns {*}
 */
export function setCoupon(parameter) {
    return request({
        url: couponApi.setCoupon,
        method: 'post',
        data: parameter
    })
}

export function couponList(parameter) {
    return request({
        url: couponApi.couponList,
        method: 'get',
        params: parameter
    })
}

