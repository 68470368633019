<template>
  <div class="stop px-4 py-2">
    <!-- <div class="py-4 title text-black border-b border-gray-300 font-bold">生成优惠券</div> -->
    <div class="content_item pt-2">
      <div class="content_item_title font-bold py-1">选择生成优惠券金额或手动填写</div>
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <div class="item flex items-center">
            <div>生成金额：</div>
            <div class="input_box py-2 pr-4">
              <a-input style='width:200px;' type="number" size="small" placeholder="请输入" v-model="money" />
            </div>
          </div>

                <div class="py-1 flex pt-2">
                  <span
                    @click="selectSum(index)"
                    class="sum_item mr-2 cursor-pointer"
                    v-for="(item, index) in moneyList"
                    :key="index"
                    :class="index == select ? 'sum_active' : ''"
                  >{{ item }}元</span>
                </div>
                <div class="item flex items-center">
            <div>生成数量：</div>
            <div class="input_box py-2 pr-4">
              <a-input type="number" size="small" placeholder="请输入" v-model="num" />
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <a-button style='background-color:#294DEA;color:#fff' size="small" type="" @click="setting">立即生成</a-button>
        </div>
      </div>

      <div class="pt-4">
        <div class="history">生成历史</div>
        <a-table
          :data-source="dataSource"
          :columns="columns"
          @change="change"
          :pagination="pagination"
        ></a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { setCoupon, couponList } from '@/api/coupon'
export default {
  data() {
    return {
      show: true,
      value: null,
      value2: null,
      editingKey: '',
      checked: null,
      money: '',
      num: '',
      moneyList: ['10', '20', '30', '50', '100', '200'],
      select: -1,
      sum: '',
      dataSource: [],
      count: 2,
      cacheData: null,
      columns: [
        {
          title: '生成时间',
          dataIndex: 'create_time'
        },
        {
          title: '优惠券码',
          dataIndex: 'code'
        },
        {
          title: '生成金额',
          dataIndex: 'money'
        },
        {
          title: '状态',
          dataIndex: 'status_name',
          filters: [
            {
              text: '已使用',
              value: '已使用'
            },
            {
              text: '未使用',
              value: '未使用'
            }
          ],
          // filterDropdownVisible:true,
          onFilter: (value, record) => record.status_name.indexOf(value) === 0
        }
      ],
      page: 1,
      pagination: {
        total: 0,
        defaultPageSize: 6
      }
    }
  },
  created() {
    this.aquireCouponList()
  },
  methods: {
    selectSum(index) {
      this.select == index ? (this.select = -1) : (this.select = index)
    },
    change(pagination, filters) {
      if (filters.status_name && filters.status_name.length != 0) {
      } else {
        this.page = pagination.current
        this.aquireCouponList()
      }
    },
    aquireCouponList() {
      couponList({
        page: this.page,
        limit: this.pagination.defaultPageSize
      })
        .then((res) => {
          console.log('获取优惠券列表', res)
          if (res.msg == '操作成功') {
            this.dataSource = []
            let list = res.data.list
            list.map((item, index) => {
              item.key = index
              item.status == 0
                ? (item.status_name = '未使用')
                : (item.status_name = '已使用')
            })
            this.pagination.total = res.data.count
            this.dataSource.push(...list)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    setting() {
      if (this.select == -1 && this.money == '') {
        this.$message.error('请输入（选择）要生成的优惠券金额')
        return
      }
      if (this.num == '') {
        this.$message.error('请输入要生成的优惠券数量')
        return
      }
      let money = 0
      this.money == ''
        ? (money = this.moneyList[this.select])
        : (money = this.money)

      setCoupon({
        money: parseInt(money),
        num: parseInt(this.num)
      })
        .then((res) => {
          console.log('创建优惠券', res)
          if ((res.msg = '操作成功')) {
            this.$message.success('优惠券创建成功')
            this.aquireCouponList()
            this.select = -1
            this.money = ''
            this.num = ''
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
}
.input_box {
  width: 250px;
}
.client_input_box {
  width: 180px;
}
.content_item_title {
  font-size: 16px;
  color: #000000;
}
.history {
  font-size: 16px;
  padding-bottom: 10px;
}
.sum_item {
  width: 65px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #d8d8d8;
  user-select: none;
}
.sum_active {
  border-color: @primary-color;
  color: @primary-color;
}
.shouqi {
  user-select: none;
}
.list_title {
  font-size: 16px;
}
.select_info {
  background: #afe8bc;
  .etc-gantanhao-copy-copy {
    font-size: 18px;
  }
  span {
    font-size: 14px;
  }
  i {
    font-style: normal;
    padding: 0px 2px;
    color: @primary-color;
  }
  .delete {
    color: red;
    user-select: none;
  }
}
.content_item {
  .item {
    .item_title {
      font-size: 16px;
    }
    span {
      font-size: 14px;
    }
    .list_title {
      color: #000000;
    }
    .list_msg {
      color: #7c7c7c;
    }
    div {
      font-size: 14px;
      color: #000000;
    }
    .item_box {
      div {
        width: 25%;
      }
    }
    span {
      font-size: 14px;
    }
  }
}
</style>
<style lang="less">
.stop {
  .circle {
    display: inline-block;
    height: 6px;
    width: 6px;
    background: @primary-color;
    border-radius: 50%;
  }
  tr td:last-child {
    display: flex;
    justify-content: flex-end;
    a:nth-child(1) {
      color: @primary-color;
    }
    a:nth-child(2) {
      color: red;
    }
  }
  th:last-child {
    text-align: right;
  }

  .ant-btn {
    display: flex;
    align-items: center;
  }
}
</style>
